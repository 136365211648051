<template>
	<!-- 蓝色标题+下划线 -->
	<div class="number-data-wrapper">
		<span class="num">{{num}}<span class="subtitle"> {{unit}}</span> </span>
		<div class="subtitle" style="margin-top: 23px;">{{type}}</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		props: ['num','type','unit'],
		mounted() {

		},
		methods: {

		}
	}
</script>
<style scoped lang="less">
	.number-data-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		.num {
			font-size: 38px;
			color: #ECFA0B;
		}

		.subtitle {
			font-size: 20px;
			color: #FBFDFD;
		}
	}
</style>
