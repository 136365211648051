<template>
  <div class="body-content">
    <banner v-if="bannerList&&bannerList.length>0" :homeBanner="bannerList"></banner>
    <!--      <el-carousel :interval="5000" :height="imgHeight" arrow="never">-->
    <!--        <el-carousel-item>-->
    <!--          <img ref="imgHeight" class="wd100" mode="widthFix" :src="require('@/static/images/technologyFinance_1.png')" @load="imgLoad" alt=""/>-->
    <!--        </el-carousel-item>-->
    <!--        <el-carousel-item>-->
    <!--          <img ref="imgHeight" class="wd100" mode="widthFix" :src="require('@/static/images/technologyFinance_2.png')" @load="imgLoad" alt=""/>-->
    <!--        </el-carousel-item>-->
    <!--      </el-carousel>-->
    <div class="wd100 pt20">
      <div class="four_data_wrapper">
        <num-data :num="setting['creditFinancing']" unit="亿" :type="'信贷融资'"></num-data>
        <div class="divider"></div>
        <num-data :num="setting['equityCapital']" unit="亿" :type="'股权融资'"></num-data>
        <div class="divider"></div>
        <num-data :num="setting['smallMediumCompany']" unit="家" :type="'科技型中小企业'"></num-data>
        <div class="divider"></div>
        <num-data :num="setting['techCompany']" unit="家" :type="'高新技术企业'"></num-data>
      </div>
    </div>
    <div class="second_row pt20">
      <div class="left">
        <div class="wrapper-title pt20">
          <span class="title">一站式服务</span>
        </div>
        <div class="wrapper-icon">
          <div class="imagediv" />
          <div @click="router2List('loanList')">
            <el-image
              :src="require('@/static/images/buttom_finance/buttom_finance1.png')"
              class="imageBut"
              fit="cover"
            />
            <div class="h1">找贷款</div>
          </div>
          <div class="imagediv" />
          <div @click="router2List('perkList')">
            <el-image
              :src="require('@/static/images/buttom_finance/buttom_finance2.png')"
              class="imageBut"
              fit="cover"
            />
            <div class="h1">找补贴</div>
          </div>
          <div class="imagediv" />
          <div @click="router2List('capitalList')">
            <el-image
              :src="require('@/static/images/buttom_finance/buttom_finance3.png')"
              class="imageBut"
              fit="cover"
            />
            <div class="h1">找风投</div>
          </div>
          <div class="imagediv" />
          <div @click="router2List('insureList')">
            <el-image
              :src="require('@/static/images/buttom_finance/buttom_finance4.png')"
              class="imageBut"
              fit="cover"
            />
            <div class="h1">找保险</div>
          </div>
          <div class="imagediv" />
        </div>
        <div class="wrapper-icon">
          <div class="imagediv" />
          <div class="imagediv" />
          <div @click="router2List('activeList')">
            <el-image
              :src="require('@/static/images/buttom_finance/buttom_finance5.png')"
              class="imageBut"
              fit="cover"
            />
            <div class="h1">找活动</div>
          </div>
          <div class="imagediv" />
          <div @click="router2List('projectList')">
            <el-image
              :src="require('@/static/images/buttom_finance/buttom_finance6.png')"
              class="imageBut"
              fit="cover"
            />
            <div class="h1">找项目</div>
          </div>
          <div class="imagediv" />
          <div @click="router2List('policyList')">
            <el-image
              :src="require('@/static/images/buttom_finance/buttom_finance7.png')"
              class="imageBut"
              fit="cover"
            />
            <div class="h1">找政策</div>
          </div>
          <div class="imagediv" />
          <div class="imagediv" />
        </div>
      </div>
      <div class="right">
        <div class="thumbs-wrapper">
          <div class="header">
            <span
              :class="{'tab-name': true,'light-blue':activeNotice == 1,'h1':activeNotice == 2}"
              @click="(activeNotice = 1)"
            >通知公告</span>
            <span
              style="margin-left: 20px"
              :class="{'tab-name': true,'light-blue':activeNotice == 2,'h1':activeNotice == 1}"
              @click="(activeNotice = 2)"
            >新闻资讯</span>
            <div class="more" @click="router2List('notice')">更多>></div>
          </div>
          <div class="content">
            <div
              class="item-wrapper h2"
              v-for="item in announcementMap[activeNotice]"
              :key="item.id"
            >
              <div class="title" @click="handleNoticeDetailClick(item)">{{item.title}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loan-card-tab title="找贷款" :dataList="loanMap"></loan-card-tab>
    <perk-card-tab title="找补贴" :dataList="subsidyList"></perk-card-tab>
    <table-tab title="找风投" :dataList="tradeList"></table-tab>
    <insure-card-tab title="找保险" :dataList="insuranceList"></insure-card-tab>
    <activ-card-tab title="找活动" :dataList="activityMap"></activ-card-tab>
    <project-card-tab title="找项目" :dataList="projectList"></project-card-tab>
  </div>
</template>

<script>
import Banner from '../../components/banner.vue';
import numData from './components/num-data.vue'
import loanCardTab from './components/loanCardTab.vue';
import perkCardTab from './components/perkCardTab.vue';
import tableTab from './components/tableTab.vue';
import insureCardTab from './components/insureCardTab.vue';
import activCardTab from './components/activCardTab.vue';
import projectCardTab from './components/projectCardTab.vue';

export default {
  name: 'tec-index',
  components: {
    Banner,
    numData,
    loanCardTab,
    perkCardTab,
    tableTab,
    insureCardTab,
    activCardTab,
    projectCardTab
  },
  data() {
    return {
      pageTopPosition: 0,
      bannerList: [],
      announcementMap: {},
      loanMap: {},
      subsidyList: [],
      tradeList: [],
      insuranceList: [],
      activityMap: {},
      projectList: [],
      setting: {
        'creditFinancing': null,
        'equityCapital': null,
        'smallMediumCompany': null,
        'techCompany': null,
      },
      imgHeight: "",
      activeNotice: 2,
      findBTdata: [
        {
          name: '科技创新全',
          code: 1,
          data: [
            { id: 1, pic: require('@/static/images/LOGO.png'), type: "科技创新券", num: "5万", des: "1、在我市注册登记、且具有独立法人地位的XXXXX", dNane: "绵阳市科技局" },
            { id: 2, pic: require('@/static/images/LOGO.png'), type: "科技保险", num: "30万", des: "1、在我市注册登记、且具有独立法人地位的XXXXX", dNane: "绵阳市科技局" }
          ]
        },
      ],
      show: false
    }
  },
  beforeRouteLeave(to, from, next) {
    this.pageTopPosition = document.getElementById('app').scrollTop
    next()
  },
  activated() {
    document.getElementById('app').scrollTop = this.pageTopPosition
  },
  created() {
    // 调用工具方法示例
    console.log(this.$util.testName());
  },
  mounted() {
    this.getIndexInfo()
  },
  methods: {
    handleNoticeDetailClick(item) {
      this.$router.push({ path: "/technologyFinance/noticeDetail", query: { id: item.id } });
    },
    getIndexInfo() {
      this.$api.technologyFinance.index().then((res) => {
        const data = res.data.data
        if (data) {
          this.bannerList = data.bannerList
          this.announcementMap = data.announcementMap
          this.loanMap = data.loanMap
          this.subsidyList = data.subsidyList
          this.tradeList = data.investmentRecordList
          this.insuranceList = data.insuranceList
          this.activityMap = data.activityMap
          this.projectList = data.projectList
          this.setting = data.setting
        }
      })
    },
    router2List(where) {
      if (where == "notice") {
        this.$router.push({ path: "/technologyFinance/notice", query: { activeNotice: this.activeNotice } });
      } else {
        this.$router.push("/technologyFinance/" + where);
      }
    },
    imgLoad() {
      // 获取图片高度
      this.$nextTick(() => {
        this.imgHeight = this.$refs.imgHeight.height + 'px'
        console.log(this.imgHeight)
      })
    },
  }
}
</script>
<style scoped lang="less">
/deep/.el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  opacity: 0.5;
}
/deep/.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 1;
}

/deep/.el-carousel--horizontal {
  overflow-x: hidden;
  border-radius: 20px;
}

.four_data_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 98px;
  width: 80%;
  margin: auto;
  .divider {
    width: 2px;
    height: 98px;
    background-color: #7d7d7d;
  }
}

.second_row {
  width: 100%;
  height: 479px;
  display: flex;
  flex-direction: row;
  .left {
    flex-grow: 3;
    width: 60%;
    flex-shrink: 0;
    margin-right: 42px;
    background-color: rgba(255, 255, 255, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .wrapper-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex: 2;
      width: 100%;
      .imageBut {
        flex-grow: 0;
        //width: 100px;
        height: 96px;
        cursor: pointer;
      }
      .imagediv {
        flex-grow: 1;
        height: 96px;
      }
    }
    .wrapper-title {
      flex: 1;
      width: 100%;
      .title {
        font-size: 38px;
      }
    }
  }

  .right {
    width: 35%;
    //flex: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .thumbs-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0 10px;
    .header {
      position: relative;
      padding: 10px;
      .tab-name {
        padding: 5px 10px;
        font-size: 24px;
        cursor: pointer;
        font-family: MicrosoftYaHei;
      }
      .more {
        position: absolute;
        bottom: 10px;
        right: 20px;
        cursor: pointer;
      }
    }
    .content {
      padding: 20px;
      .item-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        height: 60px;
        .title {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
