<template>
  <div class="pic-list-block">
    <div class="title">
      <div class="left light-blue h1">{{title}}</div>
      <div class="more"  @click="handleMoreClick">更多>></div>
    </div>
    <div class="list">
      <div class="item" :style="{'--itemWidth' : itemWidth}" v-for="(item, i) in dataList" :key="i">
        <div class="title">{{item.subsidyName}}</div>
        <div class="content" @click="handleDetailClick(item.id)">
          <div class="logo" >
            <img :src=item.pictureUrl width="192" height="108"/>
          </div>
          <div class="info">
            <div class="price"><div class="name">最高补贴金额：</div><div class="value">{{item.maxAmount}}万</div></div>
            <div class="price"><div class="name">补贴对象：</div><div class="value">{{item.subsidyObject}}</div></div>
            <div class="price"><div class="name">补贴单位：</div><div class="value">{{item.institutionName}}</div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'perkCardTab',
  props: {
    type: {
      type: String,
      default: null
    },
    showTitle:true,
    title: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      current: 0,
      itemWidth:0,
    }
  },
  computed:{
  },
  mounted() {
    // this.litsDataChange();
  },
  watch: {
    dataList: {
      handler(val) {
        this.litsDataChange();
      },
      deep: true,
      immediate: false,
    }
  },
  methods: {
    handleMoreClick() {
      this.$router.push("/technologyFinance/perkList");
    },
    handleDetailClick(id) {
      this.$router.push({path:"/technologyFinance/perkDetail",  query: {id: id}});
    },
    litsDataChange() {
        let count = this.dataList.length;
        if (count >= 5){
          this.itemWidth = '18%';
        }else if (count == 4){
          this.itemWidth = '23%';
        }else if (count == 3){
          this.itemWidth = '31%';
        }else if (count == 2){
          this.itemWidth = '48%';
        }else if (count == 1){
          this.itemWidth = '100%';
        }

    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.pic-list-block {
  margin-top: 40px;
  //padding: 0 10px;
  .title {
    display: flex;
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, .1);
    padding-bottom: 10px;
    .more {
      position: absolute;
      bottom: 10px;
      right: 20px;
      cursor: pointer;
    }
    .middle {
      display: flex;
      padding: 15px 0 0 30px;
      .item {
        position: relative;
        padding-right: 30px;
      }
      .item::after {
        position: absolute;
        content: "";
        border-left: 2px solid #fff;
        transform: rotate(30deg);
        height: 24px;
        top: 3px;
        right: 13px;
      }
    }
  }
  .list {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .item {
      width: var(--itemWidth);
      padding: 10px 0;
      box-sizing: border-box;
      height: 200px;
      background: white;
      text-align: center;
      .title{
        border-bottom: 1px dashed grey;;
        padding-top: 5px;
        display: block;
        color: black;
        font-family: "Arial Black";
        //padding: 50px 0;
      }
      .content{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        padding-top: 10px;
        .logo {
          width: 40%;
          flex: 1;
          display: block;
          padding-top: 5px;
        }
        .info{
          flex: 1;
          width: 40%;
          margin-right: 20px;
          padding-top: 5px;
          text-align: left;
          float: right;
          .price {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: smaller;
            padding: 10px 0;
            display: flex;
            flex-direction: row;
            .name{
              color: grey;
            }
            .value{
              float: right;
              color: black;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
</style>
