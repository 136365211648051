import { render, staticRenderFns } from "./projectCardTab.vue?vue&type=template&id=30230b8d&scoped=true&"
import script from "./projectCardTab.vue?vue&type=script&lang=js&"
export * from "./projectCardTab.vue?vue&type=script&lang=js&"
import style0 from "./projectCardTab.vue?vue&type=style&index=0&id=30230b8d&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30230b8d",
  null
  
)

export default component.exports