<template>
  <div class="pic-list-block">
    <div class="title">
      <div class="left light-blue h1">{{title}}</div>
      <div class="middle">
        <div
          :class="{'item': titleDic.length != (i+1),'item-end':titleDic.length == (i+1), 'light-blue': current == i}"
          v-for="(item, i) in titleDic"
          :key="i"
          @click="switchClass(item,i)"
        >{{item.name}}</div>
      </div>
      <div class="more" @click="handleMoreClick">更多>></div>
    </div>
    <div class="list">
      <div
        class="item"
        :style="{'--itemWidth' : itemWidth}"
        v-for="(item, i) in dataList[titleDic[current].code]"
        :key="i"
      >
        <div class="top-img">
          <img :src="item.pictureUrl" class="img" />
        </div>
        <div class="title h2">{{item.loanName}}</div>
        <div class="price">
          <label class="name">最高贷款额度：</label>
          <label class="value">{{item.maxAmount}}</label>
        </div>
        <div class="price">
          <label class="name">参考年化收益率：</label>
          <label class="value">{{item.annualInterestRate}}</label>
        </div>
        <div class="hot">
          <img src="../../../static/images/hot.png" height="11" width="10" />
          {{item.heat}}
        </div>
        <!--        <img class="detailBut" src="../../../static/images/detail.png" height="20" width="60" @click="handleDetailClick(item.id)"/>-->
        <div style="display: flex;justify-content: space-around;">
          <div class="link" @click="handleDetailClick(item.id)">查看详情</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loanCardTab',
  props: {
    type: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    dataList: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      titleDic: [
        {
          name: '抵质押类',
          code: 1
        },
        {
          name: '信用类',
          code: 2
        },
        {
          name: '担保类',
          code: 3
        },
        {
          name: '组合类',
          code: 4
        }
      ],
      current: 0,
      itemWidth: 0,
    }
  },
  computed: {
  },
  mounted() {
  },
  watch: {
    dataList: {
      handler(val) {
        this.litsDataChange();
      },
      deep: true,
      immediate: false,
    }
  },
  methods: {
    switchClass(item, i) {
      this.current = i
    },
    handleMoreClick() {
      this.$router.push("/technologyFinance/loanList");
    },
    handleDetailClick(id) {
      this.$router.push({ path: "/technologyFinance/loanDetail", query: { id: id } });
    },
    litsDataChange() {
      let count = this.dataList[this.titleDic[this.current].code].length;
      if (count >= 5) {
        this.itemWidth = '18%';
      } else if (count == 4) {
        this.itemWidth = '23%';
      } else if (count == 3) {
        this.itemWidth = '31%';
      } else if (count == 2) {
        this.itemWidth = '48%';
      } else if (count == 1) {
        this.itemWidth = '100%';
      }

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.pic-list-block {
  margin-top: 40px;
  //padding: 0 10px;
  .title {
    display: flex;
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 10px;
    .more {
      position: absolute;
      bottom: 10px;
      right: 20px;
      cursor: pointer;
    }
    .middle {
      display: flex;
      padding: 2px 0 0 30px;
      .item {
        position: relative;
        padding-right: 30px;
        font-size: 18px;
        cursor: pointer;
      }
      .item-end {
        position: relative;
        padding-right: 30px;
        font-size: 18px;
        cursor: pointer;
      }
      .item::after {
        position: absolute;
        content: "";
        border-left: 2px solid #fff;
        transform: rotate(30deg);
        height: 100%;
        //top: 2px;
        right: 13px;
      }
    }
  }
  .list {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .item {
      //--itemWidth:itemWidth;
      width: var(--itemWidth);
      padding: 10px 0;
      box-sizing: border-box;
      height: 300px;
      background: white;
      text-align: center;
      .detailBut {
        width: 96px;
        height: auto;
        padding-top: 15px;
        cursor: pointer;
      }
      .link {
        color: #fff;
        background: #0046c0;
        padding: 8px;
        width: 100px;
        border-radius: 20px;
        cursor: pointer;
        margin-top: 10px;
      }
      .title {
        padding-top: 10px;
        display: block;
        color: black;
        font-family: "Arial Black";
        //padding: 50px 0;
      }
      .top-img {
        display: flex;
        align-items: center;
        height: 80px;
        .img {
          width: 100%;
          max-height: 80px;
          display: block;
        }
      }

      .hot {
        font-size: smaller;
        color: black;
      }
      .price {
        font-size: smaller;
        padding: 10px 0;
        .name {
          color: grey;
        }
        .value {
          color: black;
        }
      }
    }
  }
}
</style>
