<template>
  <div class="pic-list-block">
    <div class="title">
      <div class="left light-blue h1">{{title}}</div>
      <div class="more" @click="handleMoreClick">更多>></div>
    </div>
    <div class="content bg-rgba-w-1">
      <div class="logo">
        <img src="../../../static/images/recommend.png" height="53" width="53"/>
        <label class="h1 liable">热门推荐</label>
      </div>
      <div class="list">
        <div class="item-row">
          <div class="content" v-for="(item,j) in dataList"  @click="handleDetailClick(item.id)" :key="j">
            <div class="logo">
              <img :src=item.pictureUrl height="56" width="105"/>
            </div>
            <div class="info">
              <div class="item-title h4">{{item.insuranceName}}</div>
              <div class="des">{{item.insuranceDesc}}</div>
            </div>
          </div>
        </div>
        <!-- <div class="item-row" v-if="dataList.length > 2">
          <div class="content" v-for="(item,j) in dataList.slice(2,4)" @click="handleDetailClick(item.id)" :key="j">
            <div class="logo">
              <img :src=item.pictureUrl height="56" width="105"/>
            </div>
            <div class="info">
              <div class="item-title h4">{{item.insuranceName}}</div>
              <div class="des">{{item.insuranceDesc}}</div>
            </div>
          </div>
          <div class="content" style="background: bottom;" v-if="dataList.length <3">

          </div>
        </div>
        <div class="item-row" v-if="dataList.length > 4">
          <div class="content" v-for="(item,j) in dataList.slice(4,6)" @click="handleDetailClick(item.id)" :key="j">
            <div class="logo">
              <img :src=item.pictureUrl height="56" width="105"/>
            </div>
            <div class="info">
              <div class="item-title h4">{{item.insuranceName}}</div>
              <div class="des">{{item.insuranceDesc}}</div>
            </div>
          </div>
          <div class="content" style="background: bottom;" v-if="dataList.length <6">

          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'insureCardTab',
  props: {
    type: {
      type: String,
      default: null
    },
    showTitle:true,
    title: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      current: 0,
      itemWidth:0,
    }
  },
  computed:{
  },
  mounted() {
  },
  methods: {
    handleMoreClick() {
      this.$router.push("/technologyFinance/insureList");
    },
    handleDetailClick(id) {
      this.$router.push({path:"/technologyFinance/insureDetail",  query: {id: id}});
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.pic-list-block {
  margin-top: 40px;
  //padding: 0 10px;
  .title {
    display: flex;
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, .1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    .more {
      position: absolute;
      bottom: 10px;
      right: 20px;
      cursor: pointer;
    }
    .middle {
      display: flex;
      padding: 15px 0 0 30px;
      .item {
        position: relative;
        padding-right: 30px;
      }
      .item::after {
        position: absolute;
        content: "";
        border-left: 2px solid #fff;
        transform: rotate(30deg);
        height: 24px;
        top: 3px;
        right: 13px;
      }
    }
  }
  .content{
    display: flex;
    flex-direction: row;
    height: 320px;
    margin-top: 20px;
    .logo {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 90px;
      .liable{
        margin-top: 20px;
      }
    }
    .list {
      flex: 1;
      height: 250px;
      overflow-y: auto;
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .item-row {
        flex: 1;
        width: 98%;
        margin-top: 20px;
        margin-bottom: 20px;
        box-sizing: border-box;
        // height: 85px;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
        .content{
          cursor: pointer;
          height: 85px;
          width: 48%;
          background: white;
          display: flex;
          flex-direction: row;
          text-align: center;
          .logo {
            width: 35%;
            flex: 1;
            display: block;
            margin-top: 10px;
          }
          .info{
            flex: 1;
            width: 60%;
            margin-right: 20px;
            padding-top: 5px;
            text-align: left;
            float: right;
            .item-title{
              overflow: hidden;
              padding-top: 5px;
              color: black;
              font-family: "Arial Black";
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 100%;
            }
            .des {
              padding-top: 10px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: smaller;
              color: grey;
              width: 100%;
            }
          }
        }
      }
    }
    .list::-webkit-scrollbar {
      width: 4px;
    }
    .list::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
      border-radius: 3px;
    }
    .list::-webkit-scrollbar-thumb {
      border-radius: 3px;
      height: 40px;  /* 滚动条滑块长度 */
      background-color: rgba(0,49,189,0.6);
    }
  }
}
</style>
