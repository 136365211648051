<template>
  <div class="pic-list-block">
    <div class="title">
      <div class="left light-blue h1">{{title}}</div>
      <div class="more" @click="handleMoreClick">更多>></div>
    </div>
    <div>
      <div class="table-title bold">
        <div class="investmentName">名称</div>
        <div class="buyCompanyName">买方信息</div>
        <div class="sellCompanyName">卖方信息</div>
        <div class="amount">成交额</div>
        <div class="status">状态</div>
      </div>
      <list-scroll class="box" :speed="0.5">
        <div>
          <div v-for="(item,i ) in dataList" :key="i" class="table-title">
            <div class="investmentName">{{ item.investmentName }}</div>
            <div class="buyCompanyName">{{ item.buyCompanyName }}</div>
            <div class="sellCompanyName">{{ item.sellCompanyName }}</div>
            <div class="amount">{{ item.amount }}万</div>
            <div class="status" :style="`${item.status == 1 ? 'color: #0ff' : 'color: #eca745'}`">{{ item.status == 1 ? '完成' : '交易中'}}</div>
          </div>
        </div>
      </list-scroll>
    </div>
  </div>
</template>

<script>
import ListScroll from "@/components/scroll-list.vue";
export default {
  name: 'tableTab',
  components: {
    ListScroll
  },
  props: {
    type: {
      type: String,
      default: null
    },
    showTitle:true,
    title: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      current: 0,
      itemWidth:0,
    }
  },
  created(options) {
    // let isScroll = true // 也可以定义到data里
    // this.$nextTick(() => {
    //   let div = document.getElementsByClassName('el-table__body-wrapper')[0]
    //   div.style.height = '275px'
    //   div.addEventListener('mouseenter', () => {
    //     isScroll = false
    //   })
    //   div.addEventListener('mouseleave', () => {
    //     isScroll = true
    //   })
    //   let t = document.getElementsByClassName('el-table__body')[0]
    //   setInterval(() =>{
    //     if(isScroll) {
    //       let data = this.dataList[0]
    //       setTimeout(() => {
    //         this.dataList.push(data)
    //         t.style.transition = 'all .5s'
    //         t.style.marginTop = '-41px'
    //       }, 500)
    //       setTimeout(() =>{
    //         this.dataList.splice(0,1)
    //         t.style.transition = 'all 0s ease 0s'
    //         t.style.marginTop = '0'
    //       }, 1000)
    //     }
    //   }, 2500)
    // })
  },
  computed:{
  },
  mounted() {

  },
  methods: {
    handleMoreClick() {
      this.$router.push("/technologyFinance/capitalList");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.title {
    margin-top: 40px;
    display: flex;
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, .1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    .more {
      position: absolute;
      bottom: 10px;
      right: 20px;
      cursor: pointer;
    }
}
.box {
  height: 226px;
}
.table-title {
  font-size: 14px;
  background-color: rgba(255, 255, 255, .1);
  display: flex;
  line-height: 44px;
  border-bottom: 1px solid #fff;
  >div {
    box-sizing: border-box;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // cursor: pointer;
  }
  .investmentName {
    width: 23%;
  }
  .buyCompanyName {
    width: 23%;
  }
  .sellCompanyName {
    width: 23%;
  }
  .amount {
    width: 20%;
  }
  .status {
    width: 11%;
  }
  &:hover {
    background: #615f5f;
  }
}
.table-title.bold {
  font-weight: bold;
  background-color: rgba(255, 255, 255, .2);
  line-height: 50px;
}

</style>
