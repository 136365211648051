<template>
  <div class="pic-list-block">
    <div class="title">
      <div class="left light-blue h1">{{title}}</div>
      <div class="more" @click="handleMoreClick">更多>></div>
    </div>
    <div class="content-big bg-rgba-w-1">
      <div class="logo">
        <div :class="['item',current==1?'item-select':'']" @click="switchClass(1)">
          <img class="pic" src="../../../static/images/icon/cup.png" height="31" width="31"/>
          <label class="h1 liable">培训活动</label>
        </div>
        <div :class="['item',current==2?'item-select':'']" @click="switchClass(2)">
          <img class="pic" src="../../../static/images/icon/cup.png" height="31" width="31"/>
          <label class="h1 liable">路演活动</label>
        </div>
        <div :class="['item',current==3?'item-select':'']" @click="switchClass(3)">
          <img class="pic" src="../../../static/images/icon/more.png" height="31" width="31"/>
          <label class="h1 liable">其他活动</label>
        </div>

      </div>
      <div class="list">
        <div class="item-col" v-for="(item, i) in dataList[current]" :key="i">
          <div class="content">
            <div class="pic"> <img :src=item.pictureUrl class="pic"/></div>
            <div class="title-name h4">{{item.activityName}}</div>
            <div style="display: flex;justify-content: space-around;margin-top: 10px;">
              <div class="link" @click="handleDetailClick(item.id)">查看详情</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'activCardTab',
  props: {
    type: {
      type: String,
      default: null
    },
    showTitle:true,
    title: {
      type: String,
      default: ''
    },
    dataList: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      current: 1
    }
  },
  computed:{
  },
  mounted() {
  },
  methods: {
    handleMoreClick() {
      this.$router.push("/technologyFinance/activeList");
    },
    switchClass(i) {
      this.current = i
    },
    handleDetailClick(id) {
      this.$router.push({path:"/technologyFinance/activeDetail",  query: {id: id}});
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.pic-list-block {
  margin-top: 40px;
  //padding: 0 10px;
  .title {
    display: flex;
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, .1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    .more {
      position: absolute;
      bottom: 10px;
      right: 20px;
      cursor: pointer;
    }
    .middle {
      display: flex;
      padding: 15px 0 0 30px;
      .item {
        position: relative;
        padding-right: 30px;
      }
      .item::after {
        position: absolute;
        content: "";
        border-left: 2px solid #fff;
        transform: rotate(30deg);
        height: 24px;
        top: 3px;
        right: 13px;
      }
    }
  }
  .content-big {
    display: flex;
    flex-direction: row;
    height: 320px;
    margin-top: 20px;
    justify-content: space-between;
    .logo {
      background: white;
      width: 19%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 20px 0;
      text-align: center;
      .item-select{
        border-left: 2px solid blue;
        background: rgba(25, 25, 25, .1);
      }
      .item{
        height: 50px;
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        cursor: pointer;
        .pic{
          margin-left: 30px;
        }
        .liable{
          padding-left: 10px;
          color: black;
		      cursor: pointer;
        }
      }
    }
    .list {
      background: white;
      height: 100%;
      width: 80%;
      display: flex;
      justify-content: flex-start;;
      flex-direction: row;
      .item-col {
        width: 32%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .content{
          width: 100%;
          background: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .pic{
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            height: 145px;
            width: 258px;
            background-size: cover;
            //background-image: url("../../../static/images/trainBak.png");
          }
          .title-name{
            color: black;
            width: 80%;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-top: 10px;
          }
          .link {
            color: #fff;
            background: #0046C0;
            padding: 8px 30px;
            width: 70px;
            border-radius: 20px;
            cursor: pointer;
            margin-top: 10px;
          }
          .detailBut{
            width: 96px;
            height: auto;
            padding-top: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
