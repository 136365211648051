<template>
  <div class="pic-list-block">
    <div class="title">
      <div class="left light-blue h1">{{title}}</div>
      <div class="more" @click="handleMoreClick">更多>></div>
    </div>
    <div class="list">
      <div class="item" :style="{'--itemWidth' : itemWidth}" v-for="(item, i) in dataList.slice(0,5)" :key="i">
        <img class="icon" src="../../../static/images/icon/project.png" height="110" width="110"/>
        <div class="text-left">
          <div class="title h2">{{item.projectName}}</div>
          <div class="price"><label class="name">所属领域：</label><label class="value">{{fieldDic[item.field]}}</label></div>
          <div class="price"><label class="name">融资类型：</label><label class="value">{{typeDic[item.financeType]}}</label></div>
          <div class="price"><label class="name">融资金额：</label><label class="value">{{item.financeAmount}}万</label></div>
        </div>
<!--        <img class="detailBut" src="../../../static/images/detail.png" height="20" width="60" @click="handleDetailClick(item.id)"/>-->
        <div style="display: flex;justify-content: space-around;margin-top: 10px;">
          <div class="link" @click="handleDetailClick(item.id)">查看详情</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'projectCardTab',
  props: {
    type: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      typeDic:{1:"股权融资",2:"债权融资",3:"信贷融资",4:"其他"},
      fieldDic:{1:"生物医药",2:"先进制造",3:"现代服务业",4:"现代农业"},
      current: 0,
      itemWidth:0,
    }
  },
  computed:{
  },
  mounted() {
    // this.litsDataChange();
  },
  watch: {
    dataList: {
      handler(val) {
        this.litsDataChange();
      },
      deep: true,
      immediate: false,
    }
  },
  methods: {
    handleMoreClick() {
      this.$router.push("/technologyFinance/projectList");
    },
    handleDetailClick(id) {
      this.$router.push({path:"/technologyFinance/projectDetail",  query: {id: id}});
    },
    litsDataChange() {
        let count = this.dataList.length;
        if (count >= 5){
          this.itemWidth = '18%';
        }else if (count == 4){
          this.itemWidth = '23%';
        }else if (count == 3){
          this.itemWidth = '31%';
        }else if (count == 2){
          this.itemWidth = '48%';
        }else if (count == 1){
          this.itemWidth = '100%';
        }

    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.pic-list-block {
  margin-top: 40px;
  //padding: 0 10px;
  .title {
    display: flex;
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, .1);
    padding-bottom: 10px;
    .more {
      position: absolute;
      bottom: 10px;
      right: 20px;
      cursor: pointer;
    }
  }
  .list {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    .item {
      //--itemWidth:itemWidth;
      width: var(--itemWidth);
      padding: 10px 0;
      box-sizing: border-box;
      height: 300px;
      background: white;
      text-align: center;
      .icon{
        margin-top: -55px;
      }
      .text-left{
        padding-left: 30px;
        text-align: left;
        .title{
          padding-top: 10px;
          display: block;
          color: black;
          font-family: "Arial Black";
        }
        .price {
          font-size: smaller;
          padding: 10px 0;
          .name{
            color: grey;
          }
          .value{
            color: black;
          }
        }
      }
      .link {
        color: #fff;
        background: #0046C0;
        padding: 8px;
        width: 100px;
        border-radius: 20px;
        cursor: pointer;
      }
      .detailBut{
        width: 96px;
        height: auto;
        padding-top: 15px;
        cursor: pointer;
      }
    }
  }
}
</style>
